<template>
	<validation-observer ref="validationObserver">
		<div class="card py-2">

			<!-- Payment Info -->
			<div v-if="details">
				<div class="row p-2">
					<div class="col-lg-4 mb-1">
						<h5>Professional: <b>{{ details.fullName }}</b></h5>
					</div>
					<div class="col-lg-4 mb-1">
						<h5>Email: <b>{{ details.email }}</b></h5>
					</div>
				</div>

				<div class="row px-2">
					<div class="col-lg-4 mb-1">
						<h5>Full Amount: <b>${{ details.fullAmountInUSD }}</b></h5>
					</div>
					<div class="col-lg-4 mb-1">
						<h5>Amount: <b>${{ details.amountInUSD }}</b></h5>
					</div>
				</div>
			</div>

			<hr>

			<div class="pt-2">

				<!--  Payer  -->
				<div class="row px-2">
					<div class="col-lg-4 mb-1">
						<label>
							<span>Payer Name</span>
							<sup v-if="!isPaymentCompleted" class="text-danger">*</sup>
						</label>
						<validation-provider
							#default="{ errors }"
							rules="required"
							name="Payer Name"
						>
							<b-form-input
								id="payerName"
								v-model="payerName"
								:readonly="isPaymentCompleted"
								:state="errors.length > 0 ? false:null"
								placeholder="Payer Name"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</div>
					<div class="col-lg-4 mb-1">
						<label>
							<span>Medium of Transfer</span>
							<sup v-if="!isPaymentCompleted" class="text-danger">*</sup>
						</label>
						<validation-provider
							#default="{ errors }"
							rules="required"
							name="Medium of Transfer"
						>
							<b-form-input
								id="transferMedium"
								v-model="transferMedium"
								:readonly="isPaymentCompleted"
								:state="errors.length > 0 ? false:null"
								placeholder="Medium of Transfer"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</div>
					<div class="col-lg-4 mb-1">
						<label>
							<span> Date of Transfer</span>
							<sup v-if="!isPaymentCompleted" class="text-danger">*</sup>
						</label>
						<validation-provider
							#default="{ errors }"
							rules="required"
							name="Date of Transfer"
						>
							<b-form-datepicker id="dateTransfer"
																 v-model="selectedDate"
																 :readonly="isPaymentCompleted"
																 :max="maxDate"
																 :state="errors.length > 0 ? false:null"
																 placeholder="Date of Transfer"/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</div>
				</div>
				<!-- / Payer  -->

				<!--  Country (From/To)   -->
				<div class="row px-2">
					<div class="col-lg-4 mb-1">
						<label>
							<span>Sender Country</span>
							<sup v-if="!isPaymentCompleted" class="text-danger">*</sup>
						</label>
						<validation-provider
							#default="{ errors }"
							rules="required"
							name="Sender Country"
						>
							<b-form-select
								id="countryFrom"
								v-model="senderCountry"
								:disabled="isPaymentCompleted"
								:state="errors.length > 0 ? false:null"
								:options="countries"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</div>
					<div class="col-lg-4 mb-1">
						<label>
							<span>Receiver Country</span>
							<sup v-if="!isPaymentCompleted" class="text-danger">*</sup>
						</label>
						<validation-provider
							#default="{ errors }"
							rules="required"
							name="Receiver Country"
						>
							<b-form-select
								id="countryTo"
								v-model="receiverCountry"
								:disabled="isPaymentCompleted"
								:state="errors.length > 0 ? false:null"
								:options="countries"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</div>
				</div>
				<!-- / Country (From/To)   -->

				<!--  Upload Proofs   -->
				<div class="row px-2">
					<div class="col-lg-4 mb-1" v-if="!isPaymentCompleted">
						<label>
							<span>Upload Proofs</span>
							<sup v-if="!isPaymentCompleted" class="text-danger">*</sup>
						</label>
						<validation-provider
							#default="{ errors }"
							rules="required"
							name="Upload Proofs"
						>
							<b-form-file type="file" :state="errors.length > 0 ? false:null"
													 accept="application/msword, text/plain, application/pdf, image/*" multiple v-model="selectedProofs"/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</div>

					<div class="col-lg-auto mb-1 mt-2" v-else>
						<span>Proofs</span>
						<div class="d-flex flex-wrap">
							<div v-for="(proof, index) in proofs" :key="index"
									 class="d-flex align-items-center border mr-5 mt-2 p-3">
								<i class="fa-regular fa-file fa-2x"></i>

								<div class="ml-2">
									<h6 class="mb-0">{{ 'Proof ' + (index + 1) }}</h6>
								</div>
								<div class="ml-3">
									<b-button
										variant="flat-info"
										class="btn-icon"
										:href="proof.proofUrl"
										target="_blank"
									>
										<feather-icon icon="DownloadCloudIcon"/>
									</b-button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- / Upload Proofs   -->
			</div>

			<!--  Pay Button  -->
			<div class="row px-2 justify-content-end" v-if="details && details.status !== 'Paid'">
				<div class="col-lg-4 mb-1 d-flex justify-content-end">
					<b-button @click="pay" :disabled="details.paymentStatusCategory === 'not_due'" variant="primary" class="w-50 mt-2">Pay</b-button>
				</div>
			</div>
			<!-- / Pay Button  -->
			<hr>
			<!-- / Payment Info -->

			<!-- Table -->
			<b-table
				ref="paymentsTable"
				class="position-relative text-nowrap mt-2 mb-5"
				:items="payments"
				responsive
				:fields="tableFields"
				show-empty
				empty-text="No records found"
			>

				<!-- Column: Project Id -->
				<template #cell(projectId)="data">
					<div class="font-weight-bold d-block text-nowrap">
						{{ data.item.projectNumber }}
					</div>
				</template>

				<!-- Column: Full Name -->
				<template #cell(owner)="data">
					<div class="font-weight-bold d-block text-nowrap">
						{{ data.item.ownerFullName }}
					</div>
				</template>

				<!-- Column: Email -->
				<template #cell(email)="data">
					<div class="text-nowrap">
						<span class="align-text-top">{{ data.item.ownerEmail }}</span>
					</div>
				</template>

				<!-- Column: Total Amount -->
				<template #cell(totalAmount)="data">
					<div class="text-nowrap">
						<span class="align-text-top">${{ data.item.fullAmountInUSD }}</span>
					</div>
				</template>

				<!-- Column: Payment Month -->
				<template #cell(month)="data">
					<div class="text-nowrap">
						<span class="align-text-top">{{ data.item.paymentMonth }}</span>
					</div>
				</template>

				<!-- Column: Payment Month Part -->
				<template #cell(paymentMonthPart)="data">
					<div class="text-nowrap">
						<span class="align-text-top">{{ data.item.paymentMonthPart }} </span>
					</div>
				</template>

				<!-- Column: Payment Year -->
				<template #cell(year)="data">
					<div class="text-nowrap">
						<span class="align-text-top">{{ data.item.paymentYear }}</span>
					</div>
				</template>

				<!-- Column: Status -->
				<template #cell(status)="data">
					<div class="text-nowrap">
						<span class="align-text-top">{{ statusLabel(data.item.status) }}</span>
					</div>
				</template>

				<!-- Column: Commission -->
				<template #cell(commission)="data">
					<div class="text-nowrap">
						<span class="align-text-top text-capitalize">{{ getBainkomPercentage(data.item.bainkomPercentage) }}</span>
					</div>
				</template>

				<!-- Column: Total Due -->
				<template #cell(totalDue)="data">
					<div class="text-nowrap">
						<span class="align-text-top text-capitalize">${{ data.item.amountInUSD }}</span>
					</div>
				</template>
			</b-table>
			<!-- / Table -->
		</div>
	</validation-observer>
</template>

<script>
import {
	BButton,
	BCol,
	BFormDatepicker,
	BFormFile,
	BFormInput,
	BFormSelect,
	BModal,
	BPagination,
	BRow,
	BTable
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import base64Encode from '@/libs/UploadFilesService'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {required,} from '@validations'

const status = {
	Paid: "Paid",
	NotPaid: "Not Paid",
};

export default {
	props: ["professionalId", "monthNumber", "year", "monthPart"],

	components: {
		BFormInput,
		ValidationProvider,
		ValidationObserver,
		BFormSelect,
		BFormFile,
		BFormDatepicker,
		vSelect,
		BRow,
		BCol,
		BButton,
		BTable,
		BModal,
		BPagination
	},

	data() {
		return {
			tableFields: [
				{
					key: 'projectId',
					sortable: false
				},
				{
					key: 'owner',
					sortable: false
				},
				{
					key: 'email',
					sortable: false
				},
				{
					key: 'totalAmount',
					sortable: false
				},
				{
					key: 'month',
					sortable: false
				},
				{
					key: 'paymentMonthPart',
					sortable: false
				},
				{
					key: 'year',
					sortable: false
				},
				{
					key: 'status',
					sortable: false
				},
				{
					key: 'commission',
					label: 'commission%',
					sortable: false
				},
				{
					key: 'totalDue',
					sortable: false
				}
			],
			payments: [],
			details: null,
			countries: [],
			payerName: null,
			transferMedium: null,
			selectedDate: null,
			senderCountry: null,
			receiverCountry: null,
			selectedProofs: [],
			proofs: [],
			required,
		}
	},
	directives: {
		Ripple,
	},
	computed: {
		maxDate() {
			const today = new Date().toISOString().split("T")[0];
			return today;
		},
		isPaymentCompleted() {
			return this.details?.status === "Paid";
		}
	},

	watch: {
		selectedProofs(newValue, oldValue) {
			if (newValue !== oldValue) {
				if (newValue) {
					newValue.map((proof, index) => {

						base64Encode(proof)
							.then(value => {
								const config = {headers: {'Content-Type': 'application/json'}}
								this.$http.post('/uploads', JSON.stringify(value), config)
									.then(response => {
										this.selectedProofs[index].proofUrl = response.data;
									})
							});
					})
				}
			}
		}
	},

	methods: {
		async fetchPaymentDetails() {
			const response = await this.$http.get(`payments/${this.professionalId}/${this.monthNumber}/${this.year}/${this.monthPart === 'Part 1' ? 1 : 2}`);
			const {results, details} = response.data;

			this.payments = results;
			this.details = details;

			if (this.details.status === "Paid") await this.fetchCompletedPaymentDetails();
		},

		async pay() {
			const valid = this.$refs.validationObserver.validate().then(async success => {
				if (success) {
					const response = await this.$http.post(`payments/${this.professionalId}/${this.monthNumber}/${this.year}/Create`,
						{
							payerName: this.payerName,
							transferMedium: this.transferMedium,
							paymentDate: this.selectedDate,
							senderCountryCode: this.senderCountry,
							receiverCountryCode: this.receiverCountry,
							paymentsMonth: this.monthNumber,
							proofs: this.selectedProofs
						});

					this.$toast({
						component: ToastificationContent,
						props: {
							title: "Payment Created Successfully",
							icon: 'CheckCircleIcon',
							variant: 'success',
						},
					});

					await this.fetchPaymentDetails();

				} else {
					this.$toast({
						component: ToastificationContent,
						props: {
							title: 'Check the fields validations above to be able to pay!',
							icon: 'BellIcon',
							variant: 'danger',
						},
					})
				}
				return success
			})
			return valid
		},

		async fetchCompletedPaymentDetails() {
			const response = await this.$http.get(`payments/${this.professionalId}/${this.monthNumber}/${this.year}/Completed`);

			const completedPaymentDetails = response.data;

			this.payerName = completedPaymentDetails.payerName;
			this.transferMedium = completedPaymentDetails.transferMedium;
			this.selectedDate = completedPaymentDetails.paymentDate;
			this.senderCountry = completedPaymentDetails.senderCountryCode;
			this.receiverCountry = completedPaymentDetails.receiverCountryCode;
			this.proofs = completedPaymentDetails.proofs;
		},

		getBainkomPercentage(bainkomPercentage) {
			return bainkomPercentage * 100 + "%";
		},

		statusLabel(paymentStatus){
			return status[paymentStatus] || paymentStatus;
		}
	},

	async created() {
		const response = await this.$http.get('Lookups/countries');

		const countries = response.data;
		for (const country of countries) {
			if (country.code === "ISR") continue;
			this.countries.push({text: country.name, value: country.code})
		}
		;

		await this.fetchPaymentDetails();
	}
}
</script>

<style lang="scss" scoped>
.statuses {
	.nav-link {
		background-color: white;
	}
}
</style>